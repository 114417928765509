<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="handleAdd">{{ $t('Add') }}</b-button>
        </div>
        <b-card class="mt-1">
            <b-table :items="currencies" :fields="fields" stripe>
                <template #cell(action)="data">
                    <b-button variant="warning" size="sm" @click="handleEdit(data.item)">
                        <b-icon icon="pencil"></b-icon>
                    </b-button>
                    <b-button variant="danger" class="ml-1" size="sm" @click="handleDelete(data.item)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-modal v-model="modalActive" hide-footer hide-header>
            <b-form-group :label="$t('Title')">
                <b-form-input v-model="title"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('Percentage')">
                <b-form-input v-model="percentage"></b-form-input>
            </b-form-group>

            <div class="d-flex justify-content-end">
                <b-button @click="handleSubmit">{{ $t('SubmitBtn') }}</b-button>
            </div>
        </b-modal>
        <b-modal v-model="deleteModalActive" hide-footer hide-header size="sm">
            <h4>{{ $t('DoYoiWantDeleteVAT') }}</h4>
            <div class="d-flex justify-content-between mt-4">
                <b-button variant="secondary">{{ this.$t('Cancel') }}</b-button>
                <b-button variant="danger" @click="removeCurrency">{{ this.$t('YesDelete') }}</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {getVats, createVat, updateVat, deleteVat} from '@/api/currency/index'

export default {
    name: 'Currency',
    data() {
        return {
            currencies: [],
            fields: [
                {key: 'title', label: this.$t('Title')},
                {key: 'percentage', label: this.$t('Percentage')},
                {key: 'action', label: this.$t('Action')},
            ],
            modalActive: false,
            title: '',
            percentage: null,
            id: null,
            deleteModalActive: false,
        }
    },
    created() {
        this.fetchCurrencies()
    },
    methods: {
        fetchCurrencies() {
            getVats().then(res => {
                this.currencies = res.data.data
            })
        },
        handleAdd() {
            this.title = ''
            this.percentage = null
            this.id = null
            this.modalActive = true
        },
        handleSubmit() {
            if (this.title && this.percentage) {
                if (!this.id) {
                    createVat({title: this.title, percentage: this.percentage}).then(res => {
                        this.modalActive = false;
                        this.title = ''
                        this.percentage = null
                        this.fetchCurrencies()
                    })
                } else {
                    updateVat({id: this.id, title: this.title, percentage: this.percentage}).then(res => {
                        this.title = ''
                        this.percentage = null
                        this.id = null
                        this.modalActive = false
                        this.fetchCurrencies()
                    })
                }
                
            }
        },
        handleEdit(item) {
            this.title = item.title
            this.percentage = item.percentage
            this.id = item.id
            this.modalActive = true
        },
        handleDelete(item) {
            this.id = item.id
            this.deleteModalActive = true
        },
        removeCurrency() {
            if (this.id) {
                deleteVat(this.id).then(res => {
                    this.deleteModalActive = false
                    this.fetchCurrencies()
                })
            }
        }
    }
}
</script>
<style lang="">
    
</style>