import request from '../../libs/axios'

export function getCurrencies() {
    return request({
        url: '/api/currencies',
        method: 'get',
    })
}

export function createCurrency(data) {
    return request({
        url: '/api/currencies',
        method: 'post',
        data,
    })
}

export function updateCurrency(data) {
    return request({
        url: `/api/currencies/${data.id}`,
        method: 'put',
        data,
    })
}

export function deleteCurrency(id) {
    return request({
        url: `/api/currencies/${id}`,
        method: 'delete',
    })
}

export function getVats() {
    return request({
        url: '/api/visa-vat',
        method: 'get',
    })
}

export function createVat(data) {
    return request({
        url: '/api/visa-vat',
        method: 'post',
        data,
    })
}

export function updateVat(data) {
    return request({
        url: `/api/visa-vat/${data.id}`,
        method: 'put',
        data,
    })
}

export function deleteVat(id) {
    return request({
        url: `/api/visa-vat/${id}`,
        method: 'delete',
    })
}